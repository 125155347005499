import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    display: 'flex',
  },
  bannerContainer: {
    flex: 4,
  },
  formContainer: {
    flex: 6,
  },
  arrowButton: {
    position: 'fixed',
    left: 32,
    top: 84,
  },
  container: {
    margin: 'auto',
    padding: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: '1rem',
    fontWeight: 500,
  },
  certificateMessage: {
    marginBottom: '1rem',
  },
  fieldContainer: {
    marginBottom: '2rem',
    width: '70%',
  },
  label: {
    fontWeight: 500,
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  uploadStatus: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  checkIcon: {
    color: theme.palette.success.main,
    marginRight: 12,
    fontSize: 16,
  },
  successMessage: {
    color: theme.palette.success.main,
  },
  illustration: {
    opacity: 1,
    height: '25%',
    width: '25%',
    marginBottom: '1rem',
  },
  popUpText: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  tagButtonLabel: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  excelBadge: {
    backgroundColor: 'null',
  },
  textField: {
    backgroundColor: 'white',
    minWidth: '100%',
    margin: theme.spacing(2),
  },
}));

export default useStyles;
