import React from 'react';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import NationalTaxServiceCredentialsDialog from '@fingo/lib/components/dialogs/NationalTaxServiceCredentialDialog';
import FinishRegisterAutomaticDialog from '../../components/dialogs/FinishRegisterAutomaticDialog';
import MailVerificationSnackbar from '../../components/snackbars/MailVerificationSnackbar';
import NPS from '../../components/dialogs/NPS';

const Dialogs = () => {
  const isMobile = useIsMobile('desktop');

  return (
    <>
      <NPS />
      <NationalTaxServiceCredentialsDialog />
      <FinishRegisterAutomaticDialog />
      {!isMobile && <MailVerificationSnackbar />}
    </>
  );
};

export default Dialogs;
