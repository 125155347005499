import ThemeProvider from '@mui/material/styles/ThemeProvider';
import useTheme from '@mui/material/styles/useTheme';
import createTheme from '@mui/material/styles/createTheme';
import React from 'react';
import PropTypes from 'prop-types';

const KobrosThemeProvider = ({ children }) => {
  const theme = useTheme();

  // Create a new theme instance based on the original theme
  const kobrosTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.secondary,
      },
    },
  });

  return (
    <ThemeProvider theme={kobrosTheme}>
      {children}
    </ThemeProvider>
  );
};

KobrosThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KobrosThemeProvider;
