import {
  LokalCredentialsFlipCard,
  LandingRegisterForm,
  SimulatorRegister,
  KobrosLandingRegisterForm,
  LandingNPS,
  EvaluateInvoices,
} from '../components/pilots';
import LandingRegisterWhatsappEvaluation from '../components/pilots/LandingRegisterWhatsappEvaluation';
import CollectionRegister from '../features/collection/pages/CollectionRegister';
import PartnerLoginLanding from '../features/landings/views/PartnerLoginLanding';
import PromoOperationLanding from '../features/landings/views/PromoOperationLanding';
import SimulatorRegisterVideoLanding from '../features/landings/views/SimulatorRegisterVideoLanding';

const pilotRutes = [
  {
    path: '/landing-register',
    component: LandingRegisterForm,
  },
  {
    path: '/landing-register-whatsapp',
    component: LandingRegisterWhatsappEvaluation,
  },
  {
    path: '/lokal-providers',
    component: LokalCredentialsFlipCard,
  },
  {
    path: '/simulator-register',
    component: SimulatorRegister,
  },
  {
    path: '/simulator-register-video',
    component: SimulatorRegisterVideoLanding,
  },
  {
    path: '/simulator-register-campaign',
    component: SimulatorRegister,
  },
  {
    path: '/kobros-register',
    component: CollectionRegister,
  },
  {
    path: '/collection-register',
    component: KobrosLandingRegisterForm,
  },
  {
    path: '/evaluate',
    component: LandingNPS,
  },
  {
    path: '/evaluate-invoices',
    component: EvaluateInvoices,
  },
  {
    path: '/promo-operation',
    component: PromoOperationLanding,
  },
  {
    path: '/partner-login',
    component: PartnerLoginLanding,
  },
];

export default pilotRutes;
