import React from 'react';
import Router from '@fingo/lib/components/layout/Router';
import { useFingoRoutes, useShowingTyC } from '../../hooks';
import TermsAndConditions from '../../components/onboarding/TermsAndConditions';
import Dialogs from './Dialogs';

const App = () => {
  const fingoRoutes = useFingoRoutes().filter((route) => !route.disabled);
  const showTyc = useShowingTyC();
  if (showTyc) return <TermsAndConditions />;
  return <Router routes={fingoRoutes} depth={2} />;
};

export default () => (
  <>
    <Dialogs />
    <App />
  </>
);
